
/* @media screen and (min-width: 801px) {
  .app-container {
    width: 800px;
    overflow-x: visible;
    white-space: normal;
  }
}

@media screen and (max-width: 800px) {
  .app-container {
    white-space: nowrap;
    overflow-x: auto;
  }
} */

.bold-text {
  font-family: 'Telegraf Medium'; 
  font-size: 50px;
  color:white;
}